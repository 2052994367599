import axios from 'axios'
import router from '../router'
import store from '../store'
import Qs from 'qs'
import { Message } from 'element-ui'

// 根据环境变量区分接口的默认地址
const NODE_ENV = process.env.NODE_ENV

const env = {
  "test": "https://base-oss-test.ehsy.com",
  "development": "https://base-oss-test.ehsy.com",
  // "development": "http://192.168.55.146:8080",
  "staging": "https://sso-staging.ehsy.com",
  "production": "https://base-oss.ehsy.com",
  // "production": "http://172.16.75.108:8080",
}


let _axios = axios.create({
  baseURL: env[NODE_ENV], 
  timeout: 18000000, 
  headers: {
    post: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  },  
  transformRequest: [function (data,headers) {
    const ContentType = headers['Content-Type'] || headers.common['Content-Type'] || headers.post['Content-Type']
    if(ContentType === 'application/x-www-form-urlencoded') {
      data = Qs.stringify(data)
    }
    if(ContentType === 'application/json') {
      data = JSON.stringify(data)
    }
    return data
  }],
})


/*
* 设置请求拦截器
*/
_axios.interceptors.request.use( config => {
  // 携带token
  let token = store.state.token
  let url=config.url
  let apiURL=['/oauth/getPasswordEmailNo','/oauth/login','/oauth/resetPassword'] // 不验证token
  if(!apiURL.includes(url)){
     token && (config.headers['Authorization'] = 'Bearer '+token)
     config.headers['Content-Type']='application/json'
  }else{
    if(url==='/oauth/resetPassword'){
       config.headers['Content-Type']='application/json'
    }
  }
  
  return config
}, error => {
  return Promise.reject(error)
})

/*
* 设置响应拦截器
*/

_axios.interceptors.response.use(response => {
  // token无效
  if(response.data.code === 403||response.data.code === 401) {
      localStorage.removeItem('ssoToken')
      router.push('/login')
      window.localStorage.clear();
      return Promise.reject(response.data.msg)
  }
  return response.data

}, error => {
  const  response  = error.response.data
  if(response.code) {
    // 服务器最起码返回了结果
    switch (response.code) {
      case 401: // 权限, 未登录
      localStorage.removeItem('token')
      router.push('/login')
      window.localStorage.clear();
      break;
      case 403: // token无效
      localStorage.removeItem('token')
      router.push('/login')
      window.localStorage.clear();
      break;
      case 404: // 找不到页面
        console.log(response.message)
      break;
      case 500: 
      break;
    }
    Message({
      type: 'error',
      message: `${response.message||'操作失败!'}`
  });
    return Promise.reject(response.message) 
    
  }else {
    Message({
        type: 'error',
        message: `${response.message||'操作失败!'}`
    });
    return Promise.reject(error)
  }
})

export default _axios