<template>
  <div class="menu">
    <div class="toggle-button" @click="$emit('input', !value)">
      <i 
      class="el-icon-s-unfold" 
      v-if="value"
      ></i>
      <i 
      class="el-icon-s-fold" 
      v-else
      ></i>
    </div>
    <el-menu
      class="el-menu-vertical"
      unique-opened
      :collapse="value"
      router
      :default-active="newPath"
    >
    <template v-for="(v,i) in menus">
      <menu-item v-if="!v.hidePath" :key="i"  :item="v" :basePath="v.path" />
    </template>
    
    </el-menu>
  </div>
</template>

<script>
import MenuItem from "./menu-item.vue";
import { mapState } from "vuex";
import {
  getItem,
  setItem
} from '@/utils/storage'
export default {
  name: "",
  components: {
    MenuItem
  },
  props: {
    value: {
      required: true,
      default: false
    }
  },
  data() {
    return {
      menulist: [],
      newPath:getItem('newPath')||this.$route.path||''
    };
  },
  computed: {
    ...mapState(["menus"])
  },
  watch: {
    '$route.path': function(newPath, oldPath) {
      let arr=newPath
      let arr1=arr.split('/')
      newPath= '/'+arr1[1]
      setItem('newPath',newPath)
    }
  },
  created() {
  },
  mounted() {},
  methods: {
   
  }
};
</script>

<style lang="scss" scoped>

.toggle-button {
  padding: 5px 0 2px;
  background-color: $color-theme-light;
  cursor: pointer;
  .el-icon-s-unfold,
  .el-icon-s-fold {
    font-size: 22px;
    color: #fff;
  }
}
.el-aside {
  .el-menu {
    border-right: none;
  }
}

 .el-menu-vertical:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
</style>
