import Vue from 'vue'
import Vuex from 'vuex'
import { routes } from '@/router'

import {
  getItem,
  setItem
} from '@/utils/storage'

Vue.use(Vuex)

const USER_TOKEN = 'ssoToken'
const USER_TEMP_TOKEN = 'ssoTempToken'
const USER_MENUS = 'ssoMenus'
const USER_MESSAGE = 'ssoUser'
const ROLECONFIGURATION1 = 'roleconfiguration1'
const ROLECONFIGURATION2 = 'roleconfiguration2'
const ROLECONFIGURATION3 = 'roleconfiguration3'
const CURRENTINDEX='currentIndex'
const store = new Vuex.Store({
  state: {
    token: getItem(USER_TOKEN), // 当前登录用户的token
    tempToken: getItem(USER_TEMP_TOKEN), // 临时token（首次登录重置密码用）
    menus: getItem(USER_MENUS), // 设置侧边导航栏
    user: getItem(USER_MESSAGE), // 用户信息
    roleconfiguration1:getItem(ROLECONFIGURATION1), // 配置菜单
    roleconfiguration2:getItem(ROLECONFIGURATION2), // 配置菜单
    roleconfiguration3:getItem(ROLECONFIGURATION3), // 配置菜单
    currentIndex:localStorage.getItem(CURRENTINDEX)||'0'
  },
  mutations: {
    setCurrentIndex(state, data){
      state = data
      setItem(CURRENTINDEX, state)
    },
    setRoleconfiguration1(state, data){
      state = data
      setItem(ROLECONFIGURATION1, state)
    },
    setRoleconfiguration2(state, data){
      state = data
      setItem(ROLECONFIGURATION2, state)
    },
    setRoleconfiguration3(state, data){
      state = data
      setItem(ROLECONFIGURATION3, state)
    },
    setToken(state, data) {
      state.token = data
      setItem(USER_TOKEN, state.token)
    },
    setTempToken(state, data) {
      state.tempToken = data
      setItem(USER_TEMP_TOKEN, state.tempToken)
    },
    setUser(state, data) {
      data.lastname = data.email ? data.email.split('@')[0] : '';
      state.user = data
      setItem(USER_MESSAGE, state.user)
    },
    setMenus(state) {
      const filters = ['*', '/login','/resetPwd','/forgetPwd']
      let menus = []
      routes.forEach(item => {
        if (!filters.includes(item.path)) {
          if (item.path === '/') {
            item.children.forEach(rou => {
              menus.push(rou)
            })
          } else {
            menus.push(item)
          }
        }
      })
      if(!getItem('ssoUser')?.isManager){
        state.menus = menus.filter(item=>item.path=='/home')
      }
      state.menus = menus
    }
  },
  actions: {

  }
})

export default store
