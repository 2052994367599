<template>
	<el-container class="layout">
		<el-header>
            <div @click="routerJump('/home')" style="cursor:pointer;">
                <img class="layout__user-img" src="../../assets/logo.png" alt/>
                <span class="layout__user-name">西域统一登录平台</span>
            </div>
            <div style="margin-right: 20px">
                <el-dropdown trigger="hover">
                  <span class="el-dropdown-link">
                      <i class="el-icon-user-solid"></i>
                    Hello，{{$store.state.user.firstname ? $store.state.user.firstname : $store.state.user.lastname}}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <span @click="routerJump('/resetPwd')">修改密码</span>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <span @click="logout">退出登录</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

<!--                <i class="el-icon-user-solid"></i>-->
<!--                <span class="layout__user-name">Hello，{{$store.state.user.firstname}}&nbsp;</span>-->
<!--                <i class="el-icon-arrow-down"></i>-->
            </div>

<!--			<div>-->
<!--				<router-link to="/user/passwordChange" style="margin-right: 20px">-->
<!--					<el-button type="primary" size="small">修改密码</el-button>-->
<!--				</router-link>-->
<!--				<el-button type="primary" size="small" @click="logout">退出</el-button>-->
<!--			</div>-->
		</el-header>
<!-- <div>{{ breadcrumbList }}</div> -->
		<el-container class="el-container">
			<el-aside
					class="layout__el-aside"
					width="atuo"
			>
				<Menu v-model="isCollapse" v-if="$store.state.user.isManager"></Menu>
			</el-aside>
			<el-main class="scroll-to-top">
				<!-- 面包屑 -->
				 <!-- v-if="$store.state.user.type == 1" -->
				<div>
					<el-breadcrumb
							separator-class="el-icon-arrow-right"
							v-if="breadcrumbVisible">
						<el-breadcrumb-item
								:to="item.isChildren?item.newPath:item.path"
								v-for="item in breadcrumbList"
								:key="item.path">
							{{ item.meta.title }}
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<router-view class="layout__router-view">
                </router-view>
                <el-backtop target=".scroll-to-top" :bottom="50" :right="50" :visibility-height="10">
                    <div style="{height: 100%;width: 100%;
                        background-color: #f2f5f6;color: #1989fa;
                        box-shadow: 0 0 6px rgba(0,0,0, .12);border-radius: 50%;
                        text-align: center;line-height: 40px;
                        }"><i class="el-icon-arrow-up"></i>
                    </div>
                </el-backtop>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import Menu from "./components/menu.vue";
import { mapState } from "vuex";
import { 
    logoutApi
} from "@/api/login.js";
import {
  getItem,
  setItem
} from '@/utils/storage'

export default {
	name: "",
	components: {
		Menu,
	},
	props: {},
	data() {
		return {
			breadcrumbVisible: true,
			breadcrumbList: [],
			isCollapse: true,
		};
	},
	computed: {
    ...mapState(["menus"])
  },
	watch: {
		$route() {
			// 渲染面包屑
			this.handleBreadcrumbList();
		},
	},
	created() {
		// 渲染面包屑
		this.handleBreadcrumbList();
	},
	mounted() {
	},
	methods: {
        // 处理面包屑
		handleBreadcrumbList() {
			let arr = this.$route.path;
			let array=arr.split('/').filter(item => item.length > 0);
			let newArray=[]
			array.forEach((item,i)=>{
				if(i===0){
					newArray.push('/'+item)
				}else if(i===1){
					newArray.push(newArray[0]+'/'+item)
				}
			})
			let menList=[]
			this.menus.forEach(item=>{
				if(item.children&&item.children.length>0){
					item.children.forEach(m=>{
						if(m.hidePath){
							let path=m.path.split("/").slice(0, -1).join("/");
							if(newArray.includes(path)){
								menList.push(m)
							}
						}else{
							let path=item.path+'/'+m.path
							if(newArray.includes(path)){
								menList.push({
									...m,
									newPath:path,
									isChildren:true
								})
							}
						}
					})
				}else{
					if(item.hidePath){
						let path=item.path.split("/").slice(0, -1).join("/");
						if(newArray.includes(path)){
							menList.push(item)
						}
					}else{
						if(newArray.includes(item.path)){
							menList.push(item)
						}
					}
				}
				
				
			});
			const first = menList[0]
			if (first && first.name !== "home") {
				// menList.unshift({path: "/home",name:'home', meta: {title: "首页"}})		
				this.breadcrumbVisible = true;
			} else {
				this.breadcrumbVisible = false;
			}
			this.breadcrumbList = menList;
		},

	async	logout() {
			window.localStorage.clear();
			this.$router.push("/login");
			try {
			let res=	await logoutApi()
			if(res.code===0){
				this.$message({
                        type: 'success',
                        message: '退出成功!'
                    });
			}else{
				this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });
			}
			} catch (error) {
				this.$message({
                    type: 'error',
                    message: `${error||'操作失败!'}`
                });
			}
		},

        routerJump(url) {
			window.localStorage.clear();
		    this.$router.push(url);
        }
	},
};
</script>
<style scoped lang="scss">
.layout {
	height: 100%;

	.el-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #2e9b64;

		> div {
			display: flex;
			align-items: center;
			color: #fff;
		}

		.layout__user-img {
			padding: 2px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			//border: 2px solid #fff;
		}

		.layout__user-name {
			margin-left: 10px;
			font-size: 15px;
		}
	}
}

.el-container {
	overflow: hidden;
}

::v-deep .el-aside {
	height: 100%;
	//background: #2e9b64;
}

.el-main {
	// min-width: 700px;
	background: $color-bg-lg;
	text-align: left;
	display: flex;
	flex-direction: column;

	.layout__router-view {
		padding: 10px;
		flex: 1;
		background: #fff;
		//overflow-y: scroll;
	}
}

.el-dropdown-link {
    cursor: pointer;
    color: #FFFFFF;
}
.el-icon-arrow-down {
    font-size: 12px;
}
.demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
}
</style>
