<template>
    <div>
        <!-- 无子集 -->
        <el-menu-item :index="basePath" v-if="!item.children">
            <i :class="[item.meta.icon ? item.meta.icon : 'menu-item__no-icon']"></i>
            <span class="menu-item__title" slot="title">{{item.meta.title}}</span>
        </el-menu-item>
        <!-- 有子集 -->
        <el-submenu :index="basePath" v-else>
            <template slot="title">
                <i :class="[item.meta.icon ? item.meta.icon : 'menu-item__no-icon']"></i>
                <span class="menu-item__title" slot="title">{{item.meta.title}}</span>
            </template>
            <menu-item
                    v-for="child in item.children"
                    :key="child.path"
                    :item="child"
                    :basePath="item.path+'/'+child.path"
            ></menu-item>
        </el-submenu>
    </div>
</template>

<script>
export default {
  name: "MenuItem",
  components: {},
  props: {
    item: {
      required: true
    },
    basePath: {
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style>
.menu-item__no-icon {
  margin: -3px 10px 0 0;
}
.menu-item__title {
  height: 23px;
  line-height: 20px;
}
.el-menu-item {
  text-align: left;
}

.el-menu-item [class^=el-icon-] {
  margin-top: -3px !important;
}

.el-submenu__title [class^=el-icon-] {
  margin-top: -3px !important;
}


.el-submenu__title {
  text-align: left;
}

.el-menu--collapse > div > .el-submenu > .el-submenu__title span {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
}
.el-menu--collapse > div > .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
    display: none;
}
.el-menu--collapse > div > .el-menu-item span {
    display: none;
}

</style>