import Vue from 'vue'
import store from '../store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
import Layout from "../views/layout/index.vue";
import {
  getItem,
  setItem
} from '@/utils/storage'

Vue.use(VueRouter)

export const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/notFound/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/resetPwd',
    name: 'resetPwd',
    component: () => import('../views/resetPwd/index.vue')
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import('../views/forgetPwd/index.vue')
  },
  {
    path: "/",
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: "/home",
        name:'home',
        meta: { title: '首页', icon: 'el-icon-menu' },
        component: () => import('../views/home/index.vue'),
      }
    ]
  },
  //用户管理
  {
    path: '/user',
    name: 'user',
    redirect: '/user/userMessage',
    component: Layout,
    meta: { title: '用户管理', icon: 'el-icon-user-solid'},
    children: [  
      {
        path: 'userList',
        name: 'UserList',
        component: () => import('../views/user/list/index.vue'),
        meta: { title: '用户列表',icon: 'el-icon-s-check' },
      },
    ]
  },
  // 菜单管理
  {
    path: "/",
    name:'menu',
    component: Layout,
    children: [
      {
        path: "/menu",
        name:'menu',
        meta: { title: '菜单管理', icon: 'el-icon-s-grid' },
        component: () => import('../views/menu/index.vue'),
      },
      {
        path: "/menu/detail/:id", 
        name:'detail',
        meta: { title: '菜单详情', icon: 'el-icon-notebook-2' },
        component: () => import('../views/menu/detail.vue'),
        hidePath:true
      }
    ]
  },
    // 角色管理
    {
      path: "/",
      name:'role',
      component: Layout,
      children: [
        {
          path: "/role",
          name:'role',
          meta: { title: '角色管理', icon: 'el-icon-user' },
          component: () => import('../views/role/index.vue'),
        },
        {
          path: "/role/detail/:id",
          name:'detail',
          meta: { title: '分配菜单', icon: 'el-icon-notebook-2' },
          component: () => import('../views/role/detail.vue'),
          hidePath:true
        },
        {
          path: "/role/configuration/:id",
          name:'configuration',
          meta: { title: '配置规则', icon: 'el-icon-notebook-2' },
          component: () => import('../views/role/configuration.vue'),
          hidePath:true
        }
      ]
    },
    // 日志列表
    {
      path: "/",
      name:'log',
      component: Layout,
      children: [
        {
          path: "/log",
          name:'log',
          meta: { title: '日志列表', icon: 'el-icon-document' },
          component: () => import('../views/log/index.vue'),
        }
      ]
    },
    // 字典管理
  {
    path: "/",
    name:'dictionary',
    component: Layout,
    children: [
      {
        path: "/dictionary",
        name:'dictionary',
        meta: { title: '字典管理', icon: 'el-icon-s-tools' },
        component: () => import('../views/dictionary/index.vue'),
      },
      {
        path: "/dictionary/detail/:id", 
        name:'detail',
        meta: { title: '字典详情', icon: 'el-icon-notebook-2' },
        component: () => import('../views/dictionary/detail.vue'),
        hidePath:true
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const notAuthPath = [
    '/resetPwd',
    '/forgetPwd',
    '/login'
];
// 路由守卫
router.beforeEach((to, from, next) => {
  // 进度条开始
  NProgress.start()
  if(!store.state.menus) {
    store.commit('setMenus')
  }
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '西域统一登录平台'
  }
  if(notAuthPath.indexOf(to.path) > -1) {
    return next()
  }else if(to.path!=='/home'){
    if(getItem('ssoUser')?.isManager){
      return next()
    }else{
      return next('/home')
    }
    
  } else {
    const token = store.state.token
    if (!token) return next('/login');
    return next()
  }
})

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});

// 点击面包屑重复加载同一个页面问题
const VueRouterPush =VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
