import axios from '../utils/axios'

// 登录
export function login(params) {
    return axios.post("/oauth/login", params)
}

// 获取用户信息
export function getInfoNew() {
    return axios.get("/oauth/getUserInfo")
}
// 退出登录
export function logoutApi(params) {
    return axios.post("/oauth/logout", params)
}
// 修改密码
export function changeMyPassword(params) {
    return axios.post("/userManagement/changeMyPassword", params)
}
// 忘记密码-邮箱获取验证码
export function getPasswordEmailNoAPI(params) {
    return axios.get("/oauth/getPasswordEmailNo",{params})
}
// 忘记密码-重置密码
export function resetPasswordAPI(params) {
    return axios.post("/oauth/resetPassword", params)
}
// 企微登录跳转home接口
export function getLoginHomeAPI(params) {
    return axios.get("/qyWeixin/loginHome",{params})
}